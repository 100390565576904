/*
  Handles the parsing, preview and initial validation of CSV files

  Usage:
  1. Add the `data-controller="csv-uploader"` attribute to the form element
  2. Add the `data-csv-uploader-target="fileInput"` attribute to the file input element
      The file input can have a data-required-fields attribute which includes a JSON array of fields that are required
  3. Add the `data-csv-uploader-target="preview"` attribute to the element where the preview will be displayed (a table
     will be created inside this element)
  3. Add the `data-csv-uploader-target="message"` attribute to the element where the messages will result be displayed. You can add templates here.
  4. Add the `data-csv-uploader-target="errors"` attribute to the element where errors will be displayed (a list will
      be created inside this element based on the "template" and the errors title and message will be added where there
      is a `data-insert` attribute)

      The template can be something like this:

      ```html
      <template>
        <li class="text-red-500">
          <span data-insert="title"></span>: <span data-insert="description"></span>
        </li>
      </template>
      ```
  5. Add the `data-csv-uploader-target="dropzone"` attribute to the dropzone element
  6. Add the `data-csv-uploader-target="form"` attribute to the form element that should be submitted to the backend (this
     can include additional fields and the URL to submit to)
  7. Optional, add the `data-csv-uploader-target="previewModal"` attribute to the modal element that will be used to display the
     preview on file drop or selection

      - Add `data-action="csv-uploader#accept"` attribute to the button that will trigger file upload/submission from the modal
      - Add `data-action="csv-uploader#dismiss"` attribute to the button that will dismiss the modal & cancel
*/

import { Controller } from '@hotwired/stimulus';
import { Modal } from 'flowbite';
import Papa from 'papaparse';

function removeChildElements(element, selector) {
  element.querySelectorAll(selector).forEach((child) => {
    child.remove();
  });
}

export default class extends Controller {
  static targets = [
    'fileInput',
    'preview',
    'errors',
    'message',
    'previewModal',
    'dropzone',
    'form',
    'submitButton',
    'dryRun',
  ];

  static values = { done: Boolean }

  doneValueChanged() {
    if (this.doneValue) {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.textContent = 'Close';
      }
    }
  }

  constructor(...args) {
    super(...args);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    this.maxLines = params.get('limit') || 1000;
    this.requiredFields = JSON.parse(this.fileInputTarget?.dataset?.requiredFields || "[]");
    this.errors = [];
    // In HTML, a template's child nodes are moved under the content fragment. Sometimes (like in React), this doesn't happen, so we do it manually!
    let templates = this.element.querySelectorAll('template')
    templates.forEach((template) => {
      template.content.append(...template.childNodes)
    })
    this.originaSubmitButtonText = this.submitButtonTarget.textContent;
  }

  connect() {
    this.fileInputTarget.addEventListener('change', this.handleFileSelect.bind(this));

    //Mainly used for testing to send simulated results
    this.element.addEventListener('results', this.onResults.bind(this));
    this.element.dispatchEvent(new CustomEvent('csv-uploader-controller-connected', { bubbles: true }));
  }

  disconnect() {
    this.element.removeEventListener('results', this.onResults.bind(this));
    this.fileInputTarget.removeEventListener('change', this.handleFileSelect.bind(this));
    this.clearErrors();
    this.clearResults();
  }

  onResults(event) {
    this.clearResults();
    this.clearErrors();
    this.setState(event.detail);
    this.doneValue = true;
  }

  accept(event) {
    if (this.doneValue) {
      this.dismiss(event);
      location.reload();
      return;
    };

    if (this.errors.length > 0) {
      alert('Please fix the errors before accepting');
      return;
    }

    this.clearErrors();

    let formData = new FormData(this.formTarget);
    let fileField = document.querySelector('input[type="file"]');

    formData.append('file', fileField.files[0]);
    formData.append('dry_run', this.dryRunTarget.checked);

    fetch(this.formTarget.action, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: formData,
    })
    .then(response => response.json().then(data => ({ status: response.status, data: data })))
    .then(({ status, data }) => {
      if (status >= 200 && status < 300) {
        this.setState(data);
        if (!this.hasDryRunTarget || !this.dryRunTarget.checked) {
          this.doneValue = true;
        }
      } else {
        this.drawErrors(data.errors);
      }
    })
    .catch(error => {
      console.error('Fetch error:', error);
    });
  }

  setState(results) {
    this.showRecordStatuses(results.records);
    if (results.summary.failed == results.records.length) {
      this.drawMessage("failed", "No records imported", results.message)
      if (results.errors) {
        this.drawErrors(results.errors);
      }
    } else if (results.summary.failed > 0) {
      this.drawMessage("partial", "Partial success", results.message)
      if (results.errors) {
        this.drawErrors(results.errors);
      }
    } else {
      this.drawMessage("success", "Upload successful", results.message)
    }
  }

  dismiss(event) {
    if (this.hasPreviewModalTarget) {
      this.modal.hide();
    }
    this.fileInputTarget.value = null;
    this.reset()
  }

  reset() {
    this.clearErrors();
    this.clearResults();
    this.doneValue = false;
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.textContent = this.originaSubmitButtonText;
    }
  }

  handleDragOver(event) {
    event.preventDefault(); // Necessary to allow dropping
    this.dropzoneTarget.classList.add('drag-over');
  }

  handleDragLeave(event) {
    this.dropzoneTarget.classList.remove('drag-over');
  }

  handleDrop(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.remove('drag-over');
    const file = event.dataTransfer.files[0]; // Assuming single file drop, adjust as needed
    if (file) {
      this.processFile(file);
    }
  }

  handleFileSelect(event) {
    const file = event.target.files[0];
    if (file) {
      this.processFile(file);
    }
  }

  processFile(file) {
    let lineCount = 0;
    let headerInfo = {};
    this.headerInfo = headerInfo;

    Papa.parse(file, {
      skipEmptyLines: true,
      transformHeader: (header) => {
        const lowerCaseHeader = header.replace(' ', '_').trim().toLowerCase();
        if (
          lowerCaseHeader.includes('serial') ||
          ['sn', 'serial_number', 'serial', 's/n'].includes(lowerCaseHeader)
        ) {
          headerInfo['serial_number'] = header;
          return 'serial_number';
        }
        if (['order', 'mpn', 'product'].includes(lowerCaseHeader)) {
          headerInfo['mpn'] = header;
          return 'mpn';
        }
        if (['device_type', 'type'].includes(lowerCaseHeader)) {
          headerInfo['device_type'] = header;
          return 'device_type';
        }
        if (['model', 'model_name'].includes(lowerCaseHeader)) {
          headerInfo['model'] = header;
          return 'model';
        }
        if (['email', 'e-mail'].includes(lowerCaseHeader)) {
          headerInfo['email'] = header;
          return 'email';
        }
        if (['name', 'display_name'].includes(lowerCaseHeader)) {
          headerInfo['name'] = header;
          return 'name';
        }
        // Add more transformations as needed
        return header;
      },
      complete: (results) => {
        this.previewData(results.data, headerInfo);
        this.validateData(results.data, headerInfo);
        this.clearErrors();
        this.drawErrors(this.errors);
        if (this.hasPreviewModalTarget) {
          this.modal = this.modal || new Modal(this.previewModalTarget);
          this.modal.show();
        }
      },
      header: true,
    });
  }

  previewData(data, headerInfo) {
    const previewContainer = this.previewTarget;
    previewContainer.innerHTML = ''; // Clear previous preview
    const table = document.createElement('table');
    table.classList.add('table-auto', 'w-full', 'text-left');
    // Headers
    const hr = document.createElement('tr');
    Object.keys(data[0]).forEach((header) => {
      const th = document.createElement('th');
      th.classList.add('px-4', 'py-2');
      const name = document.createElement('div');
      name.textContent = headerInfo[header] ? headerInfo[header] : header;
      name.classList.add('font-bold');
      th.appendChild(name);

      const description = document.createElement('div');
      if (headerInfo[header]) {
        description.classList.add(
          'font-normal',
          'text-sm',
          'text-gray-500',
          'mt-1',
        );
        description.textContent = "Matched to '" + header + "'";
      } else {
        description.classList.add(
          'font-normal',
          'text-sm',
          'text-red-500',
          'mt-1',
        );
        description.textContent = 'Unmatched (will be ignored)';
      }
      th.appendChild(description);
      hr.appendChild(th);
    });
    table.appendChild(hr);

    // Data
    data.forEach((row) => {
      const tr = document.createElement('tr');
      Object.keys(row).forEach((cell) => {
        const td = document.createElement('td');
        td.textContent = row[cell];
        td.classList.add('border', 'px-4', 'py-2');
        tr.appendChild(td);
      });
      table.appendChild(tr);
    });
    previewContainer.appendChild(table);
  }

  validateData(data, headerInfo) {
    this.errors = [];
    this.requiredFields.forEach((field) => {
      if (headerInfo[field] === undefined) {
        this.errors.push({ title: field, message: 'is required' });
      }
    });

    if (data.length > this.maxLines) {
      this.errors.push({
        title: '',
        message:
          'You have ' +
          data.length +
          ' records, which is too many. The maximum in one file is ' +
          this.maxLines,
      });
    }
  }

  drawMessage(type, title, message) {
    if (this.hasMessageTarget) {
      const template = this.messageTarget
      .querySelector('template#' + type + '-message') || this.messageTarget
      .querySelector('template')

      if (template) {
        const output = template.content.cloneNode(true);
        // Replace the data-insert elements with the item data
        const titleElement = output.querySelector('[data-insert="title"]');
        titleElement.textContent = title || type || titleElement.textContent;
        const descriptionElement = output.querySelector(
          '[data-insert="message"]',
        );
        descriptionElement.textContent = message;
        // Clear non-templates
        removeChildElements(this.messageTarget, ':not(template)');
        // Append
        this.messageTarget.appendChild(output);
      } else {
        this.messageTarget.textContent = (title || type) +": " + message;
      }
    }
  }

  drawError(title, message) {
    // Get the template content
    const template = this.errorsTarget
      .querySelector('template')
      .content.cloneNode(true);

    // Replace the data-insert elements with the item data
    const titleElement = template.querySelector('[data-insert="title"]');
    titleElement.textContent = title;
    const descriptionElement = template.querySelector(
      '[data-insert="message"]',
    );
    descriptionElement.textContent = message;
    this.errorsTarget.appendChild(template);
  }

  clearErrors() {
    removeChildElements(this.errorsTarget, ':not(template)')
  }

  clearResults() {
    if (this.hasErrorsTarget) {
      removeChildElements(this.errorsTarget, ':not(template)')
    }
    if (this.hasMessageTarget) {
      removeChildElements(this.messageTarget, ':not(template)');
    }

    if (this.hasPreviewTarget) {
      const table = this.previewTarget.querySelector('table');
      removeChildElements(table, '.error-message');
      table.querySelectorAll('td:first-child').forEach((record) => {
        record.textContent.replace(/^[🛑✅📝] /gu, '');
      })
    }
  }

  drawErrors(errors) {
    errors.forEach((error) => {
      this.drawError(error.title, error.message);
    });
  }

  showRecordStatuses(data) {
    // For each response, find the corresponding row in the preview and add the status with a green checkmark SVG
    // If the status is an error, add a red X SVG
    const table = this.previewTarget.querySelector('table');
    removeChildElements(table, '.error-message');
    data.forEach((record) => {
      // Find the row in the preview that corresponds to the status
      const row = table.querySelector('tr:nth-child(' + record.line + ')');
      // Add the status to the row
      const td = row.querySelector('td:first-child');

      let content = td.textContent.replace(/^[🛑✅📝] /gu, '');
      if (record.status === 'error') {
        td.textContent = "🛑 " + content;
        const span = document.createElement('span');
        span.classList.add('block', 'text-xs', 'text-red-400', 'error-message');
        span.textContent = record.error
        td.appendChild(span)
      } else if (record.action === 'created') {
        td.textContent = "✅ " + content;
      } else {
        td.textContent = "📝 " + content;
      }
    });
  }
}
