import { Controller } from '@hotwired/stimulus';

// <form_with(model: Person, data: { controller: "form_reset")> will reset on successful submit
// <form_with(model: Person, data: { controller: "form_reset", action: "turbo:submit-end->form_reset#reset"})>
const baseURL = 'https://maps.googleapis.com/maps/api/place';

export default class extends Controller {
  static targets = [
    'address_1',
    'address_2',
    'state',
    'city',
    'postal_code',
    'country',
  ];
  static values = {
    url: String,
    apikey: String,
  };

  connect() {
    // this.baseURL = 'https://maps.googleapis.com/maps/api/place'
    // this.elementId = options.elementId
    // this.passThroughURL  = options.passThroughURL
    // this.APIKEY = options.APIKEY
    this.address_1Target.addEventListener('input', async (event) => {
      const newPlace = await this.getPlaces(event.target.value);
      if (newPlace) {
        this.showOptions(newPlace.predictions);
      }
    });
  }

  getPlaces = async (text) => {
    if (text.length < 3) return;
    const input = encodeURIComponent(text);
    return fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        url: `${baseURL}/autocomplete/json?input=${input}&region=us&types=address&key=${this.apikeyValue}`,
      }),
    }).then((response) => response.json());
  };

  getPlaceDetails = async (placeId) => {
    return fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        url: `${baseURL}/details/json?place_id=${placeId}&fields=address_components%2Cadr_address%2Cformatted_address&key=${this.apikeyValue}`,
      }),
    });
  };

  onAddressClick = async (event) => {
    event.preventDefault();
    let response = await this.getPlaceDetails(event.target.id);
    response = await response.json();
    const prediction = document.querySelector('#prediction-container');
    prediction.innerHTML = '';
    this.fillInAddressComponents(response.result);
  };

  hideOptions = () => {
    let predictionContainer = document.querySelector('#prediction-container');
    predictionContainer.innerHTML = '';
  };

  showOptions = (options) => {
    if (options.length < 1) return;
    let predictionContainer = document.querySelector('#prediction-container');

    if (!predictionContainer) {
      this.address_1Target.insertAdjacentHTML(
        'afterend',
        '<div id="prediction-container"></div>',
      );
      predictionContainer = document.querySelector('#prediction-container');
    } else {
      predictionContainer.innerHTML = '';
    }

    const predictions = [];
    predictions.push('<div class="close">X</div>');
    options.forEach((option) => {
      predictions.push(
        `<div class="prediction-option text-sm text-neutral-700" id=${option.place_id}>${option.description}</div>`,
      );
    });
    predictionContainer.insertAdjacentHTML('afterbegin', predictions.join(''));
    const predictionOptions = document.querySelectorAll('.prediction-option');
    const closeButton = document.querySelector('.close');
    predictionOptions.forEach((option) => {
      option.addEventListener('click', this.onAddressClick);
    });

    closeButton.addEventListener('click', (event) => {
      this.hideOptions();
    });
  };

  findAddressComponent = (address_components, type) => {
    return address_components.filter((component) => {
      return component.types.indexOf(type) > -1;
    })[0];
  };

  fillInAddressComponents = (results) => {
    if (!results.address_components || results.address_components.length < 1)
      return;

    this.address_1Target.value = `${results.address_components[0].long_name} ${results.address_components[1].long_name}`;
    this.address_1Target.dispatchEvent(new Event('change'));

    this.cityTarget.value = this.findAddressComponent(
      results.address_components,
      'locality',
    ).short_name;
    this.cityTarget.dispatchEvent(new Event('change'));

    this.postal_codeTarget.value = this.findAddressComponent(
      results.address_components,
      'postal_code',
    ).short_name;
    this.postal_codeTarget.dispatchEvent(new Event('change'));

    this.stateTarget.value = this.findAddressComponent(
      results.address_components,
      'administrative_area_level_1',
    ).short_name;
    this.stateTarget.dispatchEvent(new Event('change'));

    this.countryTarget.value = this.findAddressComponent(
      results.address_components,
      'country',
    ).short_name;
    this.countryTarget.dispatchEvent(new Event('change'));
  };
}
