import { Fragment, useState } from 'react';
import clsx from 'clsx';

import { UsersIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { find, isEmpty } from 'lodash';

export default function AccountDropDown({
  className,
  currentUser,
  accounts,
  currentAccount,
}) {
  if (isEmpty(currentAccount)) {
    currentAccount = find(accounts, {
      short_name: window.location.host.split('.')[0],
    });
  }

  function onRootDomain(path) {
    const currentHost = window.location.host;
    const rootDomain = currentHost.split('.').slice(-2).join('.');
    const baseUrl = window.location.origin.replace(currentHost, rootDomain);
    return `${baseUrl}${path}`;
  }

  function accountSelectPath(account) {
    if (
      window.location.pathname &&
      window.location.pathname.length &&
      [
        '/',
        '/orders',
        '/trade_ins',
        '/store',
        '/people',
        '/devices',
        '/invitations',
        '/integrations',
        '/account/edit',
      ].indexOf(window.location.pathname) > -1
    ) {
      return account.url.replace(/\/+$/, '') + window.location.pathname;
    } else {
      return account.url;
    }
  }

  //console.log("Props", currentUser, accounts?.length, currentAccount);
  const [selectedAccount, setSelectedAccount] = useState(currentAccount);
  return (
    <>
      <Menu
        as="div"
        className={clsx(
          'relative inline-block text-left',
          className,
          accounts?.length > 1 ? '' : 'hidden',
        )}
      >
        <div>
          <Menu.Button className="justify-between inline-flex max-w-screen-2xl truncate rounded-md border border-gray-700 bg-gray-600 px-4 py-2 text-sm font-medium text-gray-300 shadow-sm hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            {selectedAccount?.name ||
              selectedAccount?.short_name ||
              'No Account Selected'}
            <ChevronUpDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-72 origin-top-left divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-72">
            <div className="bg-gray-200 px-4 py-3">
              <p className="hidden text-sm sm:inline">Accounts visible to</p>
              <p className="truncate text-sm font-medium text-gray-900">
                {currentUser.email || 'me'}
              </p>
            </div>
            {accounts?.length ? (
              <div className="py-1">
                {accounts.map((account) => (
                  <Menu.Item key={account.id}>
                    {({ active }) => (
                      <a
                        href={`${accountSelectPath(account)}`}
                        className={clsx(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                          selectedAccount?.id === account.id ? 'font-bold' : '',
                        )}
                      >
                        <span className="mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
                          <span className="text-sm font-medium leading-none text-white">
                            {(account.name || account.short_name)
                              .substring(0, 1)
                              .toUpperCase()}
                          </span>
                        </span>
                        {account.name || account.short_name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ) : (
              <div className="py-1">
                <span className="ml-10 px-4 text-sm italic text-gray-300">
                  No accounts visible
                </span>
              </div>
            )}
            <div className="bg-gray-50 py-1">
              <Menu.Item key="create-account">
                {({ active }) => (
                  <a
                    href={onRootDomain('/accounts/new')}
                    className={clsx(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex gap-x-4 px-4 py-2 text-sm font-semibold leading-6',
                    )}
                  >
                    <span className="h-6 w-6 flex-none items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                    Create an account
                  </a>
                )}
              </Menu.Item>
            </div>
            {currentUser?.personal_account_allowed && (
              <div className="py-1">
                <Menu.Item key="personal-account">
                  {({ active }) => (
                    <a
                      href="/my/devices"
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group block items-center px-4 py-2 text-sm',
                      )}
                    >
                      <UsersIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      No Accounts
                    </a>
                  )}
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
