import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['reveal', 'container'];
  static values = { devices: String };

  show() {
    this.revealTarget.classList.add('hidden');
    this.containerTarget.innerHTML = '';
    const devices = JSON.parse(this.devicesValue);
    devices.forEach((device) => {
      this.containerTarget.insertAdjacentHTML(
        'beforeend',
        `<div class="text-gray-500"> ${device.description} </div>`,
      );
    });
  }
}
