import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['result'];
  static values = { url: String, path: String };
  connect() {
    this.addScrollListener();
  }

  getAll() {
    fetch(this.urlValue + '/?q=', {
      headers: {
        accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // remove all child nodes from the result target
        this.resultTarget.innerHTML = '';
        // add the unassign link
        this.resultTarget.insertAdjacentHTML(
          'beforeend',
          `<li>
            <div data-search-target="item" class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <a data-turbo="true" data-turbo-method="patch" data-turbo-stream="true" class="w-full pl-2 py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300" href="${this.pathValue}">unassign</a>
              </div>
            </li>`,
        );
        data.forEach((item) => {
          // append the results to the result target
          this.resultTarget.insertAdjacentHTML(
            'beforeend',
            `<li>
              <div data-search-target="item" class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <a data-turbo="true" data-turbo-method="patch" data-turbo-stream="true" class="w-full pl-2 py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300" href="${this.pathValue + item.id}">${item.email}</a>
              </div>
             </li>`,
          );
        });

        // Add the scroll indicator
        this.resultTarget.insertAdjacentHTML(
          'beforeend',
          `<div class="scroll-indicator absolute bottom-0 left-0 right-0 flex justify-center items-center h-6 bg-gradient-to-t from-gray-200 to-transparent dark:from-gray-700 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
            </svg>
          </div>`,
        );

        // Re-add the scroll listener after updating the content
        this.addScrollListener();
        this.toggleScrollIndicator();
      });
  }
  addScrollListener() {
    const resultList = this.resultTarget;

    resultList.addEventListener('scroll', () => {
      const scrollIndicator = resultList.querySelector('.scroll-indicator');
      if (scrollIndicator) {
        if (resultList.scrollTop === 0) {
          scrollIndicator.classList.remove('hidden');
        } else {
          scrollIndicator.classList.add('hidden');
        }
      }
    });
  }

  toggleScrollIndicator() {
    const resultList = this.resultTarget;
    const scrollIndicator = resultList.querySelector('.scroll-indicator');

    if (resultList.scrollHeight > resultList.clientHeight) {
      scrollIndicator.classList.remove('hidden');
    } else {
      scrollIndicator.classList.add('hidden');
    }
  }
}
