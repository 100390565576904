import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRefWithCallback } from 'hooks/useRefWithCallback';
import { subscribe, unsubscribe } from 'utils/pubsub';

/**
 * - Creates a slider from the right side of the screen
 * - Works with the slideover_controller
 * @param {Object} content is the content of the slider (see slideover_controller.jsx)
 **/

export default function SlideOver({ open, onClose, content }) {
  const [isOpen, setOpen] = useState(open);

  const containerRef = useRefWithCallback(
    (parentNode) => {
      Array.from(content.childNodes).map((child) => {
        parentNode.appendChild(child);
      });
    },
    (parentNode) => {
      // Anything in here is fired on component unmount.
      Array.from(parentNode.childNodes).map((child) => {
        content.appendChild(child);
      });
    },
  );

  useEffect(() => {
    subscribe('slideover:close', () => setOpen(false));
    return () => {
      unsubscribe('slideover:close', () => setOpen(false));
    };
  }, []);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (!isOpen && typeof onClose === 'function') {
      onClose();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  ref={containerRef}
                  className="pointer-events-auto w-screen max-w-md"
                ></Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/*

<form
                    className="hidden flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                    method="post"
                    action="/people"
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            New Person
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Add your team member's information below.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="person-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Person name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="person[name]"
                                  id="person-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="person-emai"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Email address
                              </label>
                              <div className="mt-2">
                                <input
                                  type="email"
                                  autoComplete="off"
                                  name="person[email]"
                                  id="person-email"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <fieldset>
                              <legend className="text-sm font-medium leading-6 text-gray-900">
                                Role
                              </legend>
                              <div className="mt-2 space-y-4">
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-6 items-center">
                                    <input
                                      id="role-member"
                                      name="role"
                                      aria-describedby="role-member-description"
                                      type="radio"
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      defaultChecked
                                    />
                                  </div>
                                  <div className="pl-7 text-sm leading-6">
                                    <label
                                      htmlFor="role-member"
                                      className="font-medium text-gray-900"
                                    >
                                      Member only
                                    </label>
                                    <p
                                      id="role-member-description"
                                      className="text-gray-500"
                                    >
                                      The user will be able to see their own
                                      information and devices.
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex h-6 items-center">
                                      <input
                                        id="role-admin"
                                        name="role"
                                        aria-describedby="role-admin-description"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                    <div className="pl-7 text-sm leading-6">
                                      <label
                                        htmlFor="role-admin"
                                        className="font-medium text-gray-900"
                                      >
                                        Administrator
                                      </label>
                                      <p
                                        id="role-admin-description"
                                        className="text-gray-500"
                                      >
                                        This person will be able to place orders and see and manage all people and devices.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex h-6 items-center">
                                      <input
                                        id="role-owner"
                                        name="role"
                                        aria-describedby="role-admin-description"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                    <div className="pl-7 text-sm leading-6">
                                      <label
                                        htmlFor="role-owner"
                                        className="font-medium text-gray-900"
                                      >
                                        Owner
                                      </label>
                                      <p
                                        id="role-owner-description"
                                        className="text-gray-500"
                                      >
                                        This person wil be able to do anything
                                        with this account (including changing
                                        billing information and deleting the
                                        account).
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <input
                        type="hidden"
                        name="authenticity_token"
                        value={
                          document.querySelector('meta[name="csrf-token"]')
                            .content
                        }
                      />{" "}
                      <button
                        type="button"
                        className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Save
                      </button>
                    </div>
                  </form>

*/
