import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'item'];

  search() {
    const query = this.inputTarget.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      const text = item.textContent.toLowerCase();
      item.style.display = text.includes(query) ? '' : 'none';
    });
  }
}
