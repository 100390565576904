import { Controller } from '@hotwired/stimulus';
import AccountDropDown from 'components/AccountDropDown';
import React from 'react';
import ReactDOM from 'react-dom/client';

export default class extends Controller {
  static values = { currentUser: Object, currentTenant: Object };
  static accounts = [];

  currentUserValueChanged(value, previousValue) {
    if (this.data.root) {
      this.render();
    }
  }

  currentTenantValueChanged(value, previousValue) {
    if (this.data.root) {
      this.render();
    }
  }

  initialize() {
    fetch('/accounts.json')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.accounts = data;
        this.render();
      });
  }

  initReact() {
    this.data.root = ReactDOM.createRoot(this.element);
  }

  connect() {
    if (!this.data.root) {
      this.initReact();
    }
    this.data.root.render();
  }

  render() {
    this.data.root.render(
      <AccountDropDown
        className={this.element.class}
        currentUser={this.currentUserValue}
        currentAccount={this.currentTenantValue}
        accounts={this.accounts}
      />,
    );
  }
}
