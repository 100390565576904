import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom/client';
import SlideOver from 'components/SlideOver';

/*
  Creates a slider that slides in from the right
  - Use the "content" target to specify the content to be displayed in the slider

  Usage:
    <div data-controller="slideover">
      <button data-action="slideover#open" type="button">New Person</button>
      <div data-slideover-target="content" class="hidden">
        <%= render partial: "form", locals: {person: Person.new} %>
      </div>
    </div>

  Note: if this proves unreliable, consider this alternative implmentation that does not
        use React: https://boringrails.com/articles/tailwind-style-css-transitions-with-stimulusjs/
*/
export default class extends Controller {
  static values = { open: { type: Boolean, default: false } };
  static targets = ['content'];

  initReact() {
    this.data.root = ReactDOM.createRoot(
      this.element.appendChild(document.createElement('div')),
    );
  }

  open() {
    this.openValue = true;
    this.render();
  }

  connect() {
    if (!this.data.root) {
      this.initReact();
    }
    this.render();
  }

  render() {
    if (this.data.root) {
      this.data.root.render(
        <SlideOver
          open={() => this.openValue}
          onClose={() => (this.openValue = false)}
          content={this.contentTarget}
        ></SlideOver>,
      );
    }
  }
}
