import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StaticRoutes } from '../Router';
import ErrorBoundary from 'components/ErrorBoundary';
export default class extends Controller {
  static values = {
    currentUser: { type: Object, default: {} },
    currentTenant: { type: Object, default: {} },
  };

  initReact() {
    this.data.root = ReactDOM.createRoot(this.element);
  }

  initialize() {
    if (!this.data.root) {
      this.initReact();
    }
  }

  connect() {
    if (!this.data.root) {
      this.initReact();
    }
    this.render();
  }

  render() {
    // console.log(this.currentUserValue);
    // console.log(this.currentTenantValue);
    console.log('StimulusJS ⚡️ React');
    this.data.root.render(
      <BrowserRouter>
        <ErrorBoundary>
          <StaticRoutes />
        </ErrorBoundary>
      </BrowserRouter>,
    );
  }
}
