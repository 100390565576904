import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="auto-submit-search"
// Triggers search form post while typing
export default class extends Controller {
  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 200);
  }
}
