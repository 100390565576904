import { Controller } from '@hotwired/stimulus';

// <form_with(model: Person, data: { controller: "form_reset")> will reset on successful submit
// <form_with(model: Person, data: { controller: "form_reset", action: "turbo:submit-end->form_reset#reset"})>
export default class extends Controller {
  reset() {
    this.element.reset();
  }

  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.reset();
      }
    });
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.reset();
      }
    });
  }
}
